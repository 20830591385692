import React from 'react'
import { graphql, Link } from 'gatsby'
import _ from "lodash"

import Layout from '../components/layout'
import SEO from '../components/seo'
import BackButton from '../components/back-button'
import { rhythm, scale } from '../utils/typography'

const Tags = ({ data }) => {
    const tags = data.allMarkdownRemark.group

    return (
        <Layout>
            <SEO title="Browse all tags" keywords={['blog', 'tags', 'rohit mundra']} />
            <BackButton to='/' title='Home' />
            <h4 style={{ marginBottom: rhythm(1) }}>Tags</h4>
            <ul>
                {tags.map(tag => (
                    <li key={tag.fieldValue}>
                        <Link to={`/tags/${_.kebabCase(tag.fieldValue)}`}>{tag.fieldValue} ({tag.totalCount})</Link>
                    </li>
                ))}
            </ul>
        </Layout>
    )
}

export default Tags

export const tagsQuery = graphql`
    query TagsQuery {
        allMarkdownRemark {
            group(field: frontmatter___tags) {
              fieldValue
              totalCount
            }
        }
    }
`
