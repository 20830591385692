import React from 'react'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { rhythm, scale } from '../utils/typography'

const BackButton = ({to, title}) => (
    <Link to={to} className="backButton">
        <div className="backButton" style={{
            marginBottom: rhythm(1)
        }}>
        <FontAwesomeIcon 
            icon={faArrowLeft}
            style={{
                marginRight: rhythm(0.5),
                color: '#000 !important'
            }}
        /><b>{title}</b>
        </div>
    </Link>
)

export default BackButton